import React, {useState} from "react";
import {Trans, useTranslation} from 'gatsby-plugin-react-i18next';
import Layout from "../../../../components/layout";
import Seo from "../../../../components/seo";
import {graphql} from 'gatsby';
import LatestBlogNewsCat from '../../../../components/blog/LatestBlogNewsCat';
import BlogNav from "../../../../components/blog/blog-nav";

const IndexPage = (props) => {

  const {t} = useTranslation();
  const blogCategories = {
    "data":{
      "limit":100,
      "objects":[
          {
            "id":28157862588,
            "name":"Retail",
          },
          {
            "id":28407421064,
            "name":"health & fitness",
          },
          {
            "id":29096368379,
            "name":"healthcare",
          },
          {
            "id":30986900134,
            "name":"Pro Sports",
          },
          {
            "id":33637937006,
            "name":"Hospitality",
          },
          {
            "id":51758763319,
            "name":"Digilock",
          },
          {
            "id":60989813284,
            "name":"manufacturing",
          },
          {
            "id":60992608114,
            "name":"workspace",
          },
          {
            "id":61387586696,
            "name":"company",
          },
          {
            "id":61387586890,
            "name":"design",
          },
          {
            "id":61391655603,
            "name":"products",
          },
          {
            "id":61393215373,
            "name":"sustainability",
          },
          {
          "id":145800716918,
          "name":"education",
        }
          ],
      "offset":0,
      "total":12,
      "totalCount":12
    }
  };

  return (
      <Layout>
        <Seo title="Digilock Company News Blogs" description="Get the latest news on Digilock products and company news" />
        <div className="blog">
          <section className="hero">
            <h1>Digilock <Trans>blog</Trans></h1>
            <div className="container">
              <BlogNav />
              {
                <LatestBlogNewsCat blogData={props.data.allHubspotPost.edges} blogTopics={blogCategories}
                                whichPage={'aspire'}/>
              }
            </div>
          </section>
        </div>
      </Layout>
  )
};

export default IndexPage;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
    
  allHubspotPost(limit: 100) {
    edges {
      node {
        title,
        url,
        body,
        created,
        slug,
        topics {
          id,
          name
        },
        feature_image {
          url,
          alt_text
        }
      } 
    }
  }
}

`;


